import negate from 'lodash/fp/negate'
import isEmpty from 'lodash/fp/isEmpty'

/**
 * Checks if value is an empty object,
 * collection, map, or set.
 */
const isNotEmpty = negate(isEmpty)

// eslint-disable-next-line import/prefer-default-export
export { isNotEmpty }
